.mapboxgl-popup-content,
maplibregl-popup-content {
  border-radius: 15px;
  padding: 0;
  margin: 0;
  height: fit-content;
}

@media (min-width: 900px) {
  .css-45h32i-MuiMasonry-root > * {
    width: calc(50% - 16px) !important;
  }
}
